import Vue from "vue";
import Router from "vue-router";
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'// nprogress样式文件

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      component: () => import("@/components/default"),
      // // 跳转路由后,默认位置为最顶部
      // scrollBehavior: function (to, from, savedPosition) {
      //   return {
      //     x: 0,
      //     y: 0
      //   }
      // },
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("@/pages/home/index.vue")
        },{
          path: "/solution/industry",
          name: "industry",
          component: () => import("@/pages/solution/industry/index.vue")
        },{
          path: "/solution/application",
          name: "application",
          component: () => import("@/pages/solution/application/index.vue")
        },{
          path: "/professional",
          name: "professional",
          component: () => import("@/pages/professional_services/index.vue")
        },{
          path: "/production",
          name: "production",
          component: () => import("@/pages/production/index.vue")
        },{
          path: "/aboutUs",
          name: "aboutUs",
          component: () => import("@/pages/aboutUs/index.vue")
        },{
          path: "/news",
          name: "news",
          component: () => import("@/pages/news/index.vue"),
        },{
          path: "/shortVideo",
          name: "shortVideo",
          component: () => import("@/pages/shortVideo/index.vue"),
        },
        {
          path: "/news/:id",
          name: "details",
          component: () => import("@/pages/news/news-details/index.vue"),
        }
        
      ]
    }
  ]
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

// 如果需要 nprogress效果(进度条) 则安装 nprogress ->  yarn add nprogress
// router.beforeEach((to, from, next) => {
//   // 开启进度条
//   NProgress.start();
//   // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
//   next();
// });
//当路由跳转结束后
router.afterEach(() => {
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
  // // 关闭 进度条
  // NProgress.done()
});

export default router;
