/**
 * edit by Yuanc 2021.08.02
 */
import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import less from 'less';
import animated from 'animate.css' // npm install animate.css --save安装，在引入
import BaiduMap from 'vue-baidu-map'
// import Element from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(Element);
Vue.use(BaiduMap, {
    ak: 'rwVRDic9sEz0FQ89hhUEizH9pUvGKsRa'
});
Vue.use(animated);
// import './plugins/swiper';

Vue.use(less)
Vue.config.productionTip = false

 
new Vue({
  router, //可以简写router
  render: h => h(App),
  components: { App },
}).$mount('#app')
